.sessions-params{
  max-height: 89vh;
  overflow: auto;
  .auth-input {
  height: 60px;
border-radius: 10px !important;
text-align: center;
font-size: 25px !important;
&:focus{

border: 2px solid #1f3c908f;

box-shadow: 0px 1px 3px 2px #1f3c9069 !important;

font-weight: 400;
}
}
.card {
    // padding:5% 5% 3%;
    padding: 1% 2% 0%;
    margin-top: 15px;
    margin-bottom: 20px;
    border: none !important;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2)
}

.blue-text {
    color: #00BCD4
}

.form-control-label {
    margin-bottom: 0
}

input,
select,
textarea,
button {
    padding: 8px 15px;
    border-radius: 5px !important;
    margin: 5px 0px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    font-size: 18px !important;
    font-weight: 300
}

input:focus,select:focus,
textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #00BCD4;
    outline-width: 0;
    font-weight: 400
}

.btn-block {
    text-transform: uppercase;
    font-size: 15px !important;
    font-weight: 400;
    height: 43px;
    cursor: pointer
}

.btn-block:hover {
    color: #fff !important
}

button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0
}
button{
  background-color: $blue-primary;
}
}
