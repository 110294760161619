.feedback {
  button{
    background: #1F3C90;
    color: white;
    padding: 2%;
  }
}
.feedback-btn {
  display: block;
max-width: fit-content;
position: fixed;
bottom: 0;
right: 2%;
background: #0D53B8 ;
    padding: 5px 1%;
    color: white !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    p{
      padding-top: 3px;
    }
}
