//Text Color
.font-blue {
  color: $blue-primary;
}
.font-blue-dark {
  color: $blue-primary-dark;
}
.font-gray {
  color: $gray-primary;
}
.text-orange {
  color: #ff5801;
}
.font-size-small {
  font-size: 0.5rem;
}
.font-size-small-1 {
  font-size: 0.7rem;
}
.font-size-normal {
  font-size: 1rem;
}
.font-size1 {
  font-size: 1.5rem;
}
.font-size2 {
  font-size: 2rem;
}
.font-size2-half {
  font-size: 2.5rem;
}
.font-size3 {
  font-size: 3rem;
}
.font-size4 {
  font-size: 1.5rem !important;
}

h1 {
  font-size: 1.7vw !important;
}

a {
  list-style: none !important;
  color: inherit !important;
  & :focus {
    outline: none !important;
  }
}
button {
  border: none !important;
}
button:focus:not(:focus-visible) {
  outline: none !important;
  outline: 0px transparent !important;
}
input:focus {
  outline: 0 !important;
}
button:focus {
  outline: 0;
}
button :focus {
  // outline: none !important;
  outline: 0px transparent !important;
}
.bg-filter-btn:focus {
  outline: 0 !important;

  color: white !important;
  background-color: $blue-primary !important;
  border: 1px solid $blue-primary !important;
  border-radius: 20px !important;
  padding: 8px 25px 8px 25px !important;
  font-size: 10px !important;
}
.bg-filter-btn {
  color: $blue-primary !important;
  background-color: white !important;
  border: 1px solid $blue-primary !important;
  border-radius: 22px !important;
  // padding-left: 25px !important;
  // padding-right: 25px !important;
  font-size: 10px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  width: 120px;
  height: 43px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0d92d233;
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .bg-filter-btn {
    color: $blue-primary !important;
    background-color: white !important;
    border-color: $blue-primary !important;
    border-radius: 20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
@media (min-width: 576px){
.modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
}
}
.pdf-device{
  .col-3{
    padding: 2%;
  }
  button{
    margin: 3%;
    width: 90%;
    height: 100%;
    background: lightblue;
  }
}

.printing-screen {
  display: flex;
  margin: auto;
  // width: 80%;
}

@media print {
  .top-bar{
    display: none !important;
  }
  .side-bar{
    display: none!important;
  }
  .print-btn {
    display: none;
  }
  .printing-screen {
    display: flex;
    width: 100%;
    margin: none;
  }
}
.pdf-img {
  width: 40px;
}
.bg-gray {
  background-color: $gray-primary;
}
.pdf-btn{
  background: none;
    position: absolute;
    z-index: 99999;
    left: 91%;
    top: 3%;
    margin-top: 1%;
}
.unit{
  font-size: 1rem !important;
}
.text-black {
  color: black;
}
.bg-transparent {
  background: transparent;
}
.ant-menu {
  background: transparent;
    border: none;
    color: white;
}
.anticon {
  margin-right: 6%;
}
.settings {
  p {
    width: 100%;
  }
  label {
    display: flex;

    select {
      margin-left: auto;
      width: 100px;
      text-align: center;
    }
  }
}
.session-table{
  th{
    max-width: 70px;
    word-break: break-all;
  }
}

.loading {
  min-height: 375px;
  margin: auto;
  border-radius: 50px;
  background: $bg-primary;
  .grx-logo{
    margin-top: 1%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    background: #fff;
    padding: 2%;
  }
  p{
    color: white;
    font-weight: bolder;

  }
}
.session-td{
  button,input{
    vertical-align: middle;
  }
}
.subjects-table{

  text-align: left;
  position: relative;
  border-collapse: collapse;

th, td {
  padding: 0.25rem;
}


th {
  background: white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
}
.btn-secondary {
  background-color: #afafaf;
}
.role-btn {
  width: 200px;
  height: 200px;
}
.admin-side-btn {
display: flex;
align-items: center;
justify-content: space-between;
i{
  font-size: 25px;
}
}


.sticky-det {
  background: white !important;
  position: sticky !important;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  z-index: 999;
}
.bg-primary {
  background-color:$bg-primary;
}






.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #0D53B8;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0D53B8;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.btn-primary {
  background-color: #0D53B8 !important;
}
