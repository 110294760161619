.loginNew-bg {
  background: linear-gradient(
      220.89deg,
      rgba(255, 255, 255, 0.278) -8.31%,
      rgba(255, 255, 255, 0.878) 68.27%,
      #ffffff 100%
    ),
    url(../Assets/Logo/login-bg.png), 0% 0% no-repeat padding-box;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
}

.loginNew-fieldBox {
  background: linear-gradient(
    180deg,
    rgba(72, 137, 201, 0.522) 0%,
    rgba(255, 255, 255, 0) 95%
  );
  .loginNew-HCP-panel {
    border-left: 5px solid black;
    background-color: white;
  }
  .loginNew-form-control {
    border-left: 5px solid black !important;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 20px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .login-btn {
    display: inline-block;
    font-weight: 400;
    color: white;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #1d77d2;
    border: 1px solid #1d77d2;
    padding: 0.375rem 0.75rem;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    width: 100% !important;
  }
}
.loginNew-heading-1 {
  color: $blue-primary;
}
.login-account-heading2 {
  color: $blue-primary;
}
.loginNew-footer {
  height: 10vh;
}
.footer-vertical-line {
  border-right: 2px solid #333;
  margin-right: 8px;
  margin-left: 8px;
}
//Media quries
@media (max-width: 641px) {
  .loginNew-bg {
    background: linear-gradient(
        220.89deg,
        rgba(255, 255, 255, 0.278) -8.31%,
        rgba(255, 255, 255, 0.878) 68.27%,
        #ffffff 100%
      ),
      url(../Assets/Logo/login-bg.png), 0% 0% no-repeat padding-box;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
  .loginNew-footer {
    height: 100%;
  }
}
