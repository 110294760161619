// .wrapper {
//   width: 100%;
//   height: 100%;

//   display: flex;
// }
// .sidebar {
//   z-index: 1000;
//   display: block;
//   padding: 20px;
//   overflow-x: hidden;
//   overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
//   background-color: #f5f5f5;
//   border-right: 1px solid #eee;
//   width: 250px;
//   height: 80vh;
//   background: $blue-primary;
//   text-align: center;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }
// table {
//   border-collapse: collapse;
// }
// td {
//   list-style-type: none;
//   padding: 12px;
//   cursor: pointer;
//   font-family: "Ubuntu", sans-serif;
// }
// tr:hover {
//   background: #2f4f4f;
//   border-color: blue;
// }
// .sidebar:hover {
//   width: 200px;
// }
// .sidebar {
//   transition-property: width;
//   transition-duration: 0.4s;
// }
// td:nth-child(2) {
//   font-size: 26px;
//   text-align: left;
//   color: #5f9ea0;
// }
// .bottom {
//   border: none;
//   background: none;
//   padding: 12px;
// }
// i {
//   color: #5f9ea0;
// }
.is-active {
  background-color: #b2bec3;
  border-radius: 5px;
}
