@font-face {
  font-family: monsterat;
  src: url(../Assets/Fonts/Montserrat-Medium.ttf);
  color: inherit !important;
}
@font-face {
  font-family: segoeBold;
  src: url(../Assets/Fonts/Segoe-UI-Bold.woff);
  color: inherit !important;
}
@font-face {
  font-family: segoe;
  src: url(../Assets/Fonts/Segoe-UI.woff);
  color: inherit !important;
}
body {
  font-family: monsterat !important;
}
